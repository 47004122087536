"use client";

import { ArrowUpRight, BarChart3, Plus } from "lucide-react";
import type { Link } from "../../types";
import { LinkIcon } from "lucide-react";

interface AnalyticsOverviewProps {
  links: Link[];
  profileViews: number; // Add this prop
  setActiveTab: (tab: "profile" | "links" | "analytics") => void;
  getContrastColor: (hexColor: string) => string;
}

export function AnalyticsOverview({
  links,
  profileViews, // Add this to props
  setActiveTab,
  getContrastColor,
}: AnalyticsOverviewProps) {
  const totalClicks = links.reduce(
    (sum, link) => sum + (link.click_count || 0),
    0
  );

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-lg font-semibold text-gray-800 mb-6">
          Analytics Overview
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <h3 className="text-sm font-medium text-gray-500 mb-1">
              Total Clicks
            </h3>
            <p className="text-2xl font-bold text-gray-900">{totalClicks}</p>
            <div className="mt-2 flex items-center text-sm">
              <span className="text-green-600 font-medium flex items-center">
                <ArrowUpRight className="h-3 w-3 mr-1" />
                12%
              </span>
              <span className="text-gray-500 ml-1">vs last month</span>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <h3 className="text-sm font-medium text-gray-500 mb-1">
              Profile Views
            </h3>
            <p className="text-2xl font-bold text-gray-900">{profileViews}</p>
            <div className="mt-2 flex items-center text-sm">
              <span className="text-green-600 font-medium flex items-center">
                <ArrowUpRight className="h-3 w-3 mr-1" />
                5%
              </span>
              <span className="text-gray-500 ml-1">vs last month</span>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <h3 className="text-sm font-medium text-gray-500 mb-1">
              Click Rate
            </h3>
            <p className="text-2xl font-bold text-gray-900">32%</p>
            <div className="mt-2 flex items-center text-sm">
              <span className="text-red-600 font-medium flex items-center">
                <ArrowUpRight className="h-3 w-3 mr-1 rotate-90" />
                2%
              </span>
              <span className="text-gray-500 ml-1">vs last month</span>
            </div>
          </div>
        </div>

        <h3 className="text-md font-medium text-gray-800 mb-4">
          Link Performance
        </h3>

        {links.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Link
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    URL
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Clicks
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    CTR
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {links.map((link) => (
                  <tr key={link.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div
                          className="h-8 w-8 flex items-center justify-center mr-3 flex-shrink-0 rounded-md"
                          style={{
                            backgroundColor: link.button_color || "#000000",
                            color: getContrastColor(
                              link.button_color || "#000000"
                            ),
                          }}
                        >
                          {link.social_icon ? (
                            <img
                              src={`https://cdn.jsdelivr.net/npm/simple-icons@v9/icons/${link.social_icon}.svg`}
                              alt={`${link.title} icon`}
                              className="w-4 h-4 object-contain"
                              style={{
                                filter:
                                  getContrastColor(
                                    link.button_color || "#000000"
                                  ) === "#ffffff"
                                    ? "invert(1)"
                                    : "none",
                              }}
                            />
                          ) : (
                            <LinkIcon className="w-4 h-4" />
                          )}
                        </div>
                        <span className="font-medium text-gray-900">
                          {link.title}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-600 hover:text-indigo-900 truncate max-w-xs inline-block"
                      >
                        {link.url}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                      {link.click_count || 0}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {Math.floor(Math.random() * 30) + 10}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-lg">
            <BarChart3 className="h-12 w-12 text-gray-400 mx-auto mb-3" />
            <p className="text-gray-600 mb-4">
              No analytics data available yet
            </p>
            <button
              onClick={() => setActiveTab("links")}
              className="px-4 py-2 bg-indigo-600 text-white inline-flex items-center rounded-lg hover:bg-indigo-700 transition-colors"
            >
              <Plus className="h-4 w-4 mr-1" />
              Add Links to Track
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
