"use client";

import {
  ChevronDown,
  ChevronUp,
  Edit,
  LinkIcon,
  Plus,
  Trash2,
} from "lucide-react";
import type { Link } from "../../types";

interface LinksListProps {
  links: Link[];
  isAddingLink: boolean;
  setIsAddingLink: (isAdding: boolean) => void;
  handleEditLink: (link: Link) => Promise<void>;
  handleDeleteLink: (id: string) => Promise<void>;
  handleMoveLink: (id: string, direction: "up" | "down") => Promise<void>;
  getContrastColor: (hexColor: string) => string;
}

export function LinksList({
  links,
  isAddingLink,
  setIsAddingLink,
  handleEditLink,
  handleDeleteLink,
  handleMoveLink,
  getContrastColor,
}: LinksListProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center">
          <LinkIcon className="h-5 w-5 mr-2 text-indigo-500" />
          Your Links
        </h2>

        {!isAddingLink && (
          <button
            onClick={() => setIsAddingLink(true)}
            className="px-4 py-2 bg-indigo-600 text-white flex items-center rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Plus className="h-4 w-4 mr-1" />
            Add Link
          </button>
        )}
      </div>

      {links.length === 0 ? (
        <div className="text-center py-8 bg-gray-50 border-2 border-dashed border-gray-300 rounded-lg">
          <LinkIcon className="h-12 w-12 text-gray-400 mx-auto mb-3" />
          <p className="text-gray-600 mb-4">You don't have any links yet</p>
          <button
            onClick={() => setIsAddingLink(true)}
            className="px-4 py-2 bg-indigo-600 text-white inline-flex items-center rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Plus className="h-4 w-4 mr-1" />
            Add Your First Link
          </button>
        </div>
      ) : (
        <ul className="space-y-3">
          {links.map((link) => (
            <li
              key={link.id}
              className="flex flex-col sm:flex-row sm:items-center gap-3 p-3 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex items-center flex-1 min-w-0">
                <div
                  className="h-10 w-10 flex items-center justify-center mr-3 flex-shrink-0 rounded-md"
                  style={{
                    backgroundColor: link.button_color || "#000000",
                    color: getContrastColor(link.button_color || "#000000"),
                  }}
                >
                  {link.social_icon ? (
                    <img
                      src={`https://cdn.jsdelivr.net/npm/simple-icons@v9/icons/${link.social_icon}.svg`}
                      alt={`${link.title} icon`}
                      className="w-6 h-6 object-contain"
                      style={{
                        filter:
                          getContrastColor(link.button_color || "#000000") ===
                          "#ffffff"
                            ? "invert(1)"
                            : "none",
                      }}
                    />
                  ) : link.thumbnail ? (
                    <img
                      src={link.thumbnail || "/placeholder.svg"}
                      alt={`${link.title} thumbnail`}
                      className="w-6 h-6 object-cover rounded-full"
                    />
                  ) : (
                    <LinkIcon className="w-5 h-5" />
                  )}
                </div>

                <div className="min-w-0 flex-1">
                  <p className="font-medium text-gray-800 truncate">
                    {link.title}
                  </p>
                  <p className="text-gray-500 text-sm truncate">{link.url}</p>
                </div>

                <div className="text-gray-500 text-sm whitespace-nowrap ml-2">
                  {link.click_count || 0} clicks
                </div>
              </div>

              <div className="flex items-center gap-1 sm:gap-2 self-end sm:self-auto">
                <button
                  onClick={() => handleMoveLink(link.id, "up")}
                  className={`p-1.5 rounded-md ${
                    links.findIndex((l) => l.id === link.id) === 0
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                  }`}
                  disabled={links.findIndex((l) => l.id === link.id) === 0}
                  title="Move up"
                >
                  <ChevronUp className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleMoveLink(link.id, "down")}
                  className={`p-1.5 rounded-md ${
                    links.findIndex((l) => l.id === link.id) ===
                    links.length - 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                  }`}
                  disabled={
                    links.findIndex((l) => l.id === link.id) ===
                    links.length - 1
                  }
                  title="Move down"
                >
                  <ChevronDown className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleEditLink(link)}
                  className="p-1.5 text-indigo-600 hover:bg-indigo-50 rounded-md"
                  title="Edit"
                >
                  <Edit className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleDeleteLink(link.id)}
                  className="p-1.5 text-red-500 hover:bg-red-50 rounded-md"
                  title="Delete"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
