import type React from "react";
import Navbar from "../components/Navbar/Navbar";

const Terms: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-50 py-10 px-4">
        <div className="max-w-3xl mx-auto bg-white p-8 shadow-md">
          <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
              <p className="text-gray-700">
                Welcome to Link Sharing. By using our service, you agree to
                these Terms of Service. Please read them carefully.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">
                2. Using our Services
              </h2>
              <p className="text-gray-700">
                You must follow any policies made available to you within the
                Services. You may use our Services only as permitted by law. We
                may suspend or stop providing our Services to you if you do not
                comply with our terms or policies or if we are investigating
                suspected misconduct.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">3. Your Content</h2>
              <p className="text-gray-700">
                Our Services allow you to upload, submit, store, send or receive
                content. You retain ownership of any intellectual property
                rights that you hold in that content.
              </p>
              <p className="text-gray-700 mt-2">
                When you upload, submit, store, send or receive content to or
                through our Services, you give us a worldwide license to use,
                host, store, reproduce, modify, create derivative works,
                communicate, publish, publicly perform, publicly display and
                distribute such content.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">4. Prohibited Uses</h2>
              <p className="text-gray-700">
                You may not use our service for any illegal or unauthorized
                purpose. You must not, in the use of the Service, violate any
                laws in your jurisdiction (including but not limited to
                copyright laws).
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">5. Termination</h2>
              <p className="text-gray-700">
                We may terminate or suspend access to our Service immediately,
                without prior notice or liability, for any reason whatsoever,
                including without limitation if you breach the Terms.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">
                6. Changes to Terms
              </h2>
              <p className="text-gray-700">
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will try to provide at least 30 days notice prior to any new
                terms taking effect.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">7. Contact Us</h2>
              <p className="text-gray-700">
                If you have any questions about these Terms, please contact us.
              </p>
            </section>
          </div>

          <div className="mt-8 text-sm text-gray-500">
            Last updated: March 23, 2025
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
