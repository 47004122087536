"use client";

import type React from "react";
import { useEffect, useState } from "react";
import { useStytch } from "@stytch/react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import { ArrowRight, CheckCircle, Globe, Sparkles } from "lucide-react";

const Login: React.FC = () => {
  const stytch = useStytch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimateIn(true), 100);
  }, []);

  const handleSignUp = () => {
    setIsLoading(true);
    stytch.oauth.google.start({
      login_redirect_url: `${process.env.REACT_APP_FRONTEND_URL}/auth/callback`,
      signup_redirect_url: `${process.env.REACT_APP_FRONTEND_URL}/auth/callback`,
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-indigo-800 to-purple-900">
      <Navbar />

      <div className="container mx-auto px-4 py-16 md:py-24">
        <div
          className={`max-w-7xl mx-auto grid md:grid-cols-2 gap-12 items-center transition-all duration-700 transform ${
            animateIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          }`}
        >
          {/* Left Column - Illustration and Features */}
          <div className="hidden md:block p-8">
            <div className="relative">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-indigo-500 rounded-full opacity-20 animate-pulse"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-purple-500 rounded-full opacity-20 animate-pulse delay-700"></div>

              <div className="relative z-10 text-white mb-16">
                <h1 className="text-5xl font-bold mb-8 leading-tight">
                  Connect your world <br />
                  with <span className="text-indigo-300">Byrah</span>
                </h1>
                <p className="text-indigo-100 text-xl mb-10">
                  The simplest way to share all your content with a single link
                </p>
              </div>

              <div className="space-y-8 text-white">
                <div className="flex items-start space-x-5">
                  <div className="bg-indigo-600/30 p-3 rounded-lg">
                    <Globe className="h-7 w-7 text-indigo-300" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl">
                      One link for everything
                    </h3>
                    <p className="text-indigo-200 mt-2 text-lg">
                      Share your content, social profiles, and more
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-5">
                  <div className="bg-indigo-600/30 p-3 rounded-lg">
                    <Sparkles className="h-7 w-7 text-indigo-300" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl">
                      Customizable profile
                    </h3>
                    <p className="text-indigo-200 mt-2 text-lg">
                      Make your page match your brand
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-5">
                  <div className="bg-indigo-600/30 p-3 rounded-lg">
                    <CheckCircle className="h-7 w-7 text-indigo-300" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl">
                      Detailed analytics
                    </h3>
                    <p className="text-indigo-200 mt-2 text-lg">
                      Track clicks and optimize your content
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Login Form */}
          <div
            className={`bg-white shadow-2xl overflow-hidden transition-all duration-1000 transform ${
              animateIn
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-8"
            }`}
          >
            <div className="relative">
              <div className="absolute top-0 left-0 w-full h-3 bg-gradient-to-r from-indigo-500 to-purple-600"></div>
              <div className="absolute top-0 right-0 w-32 h-32 bg-indigo-100 opacity-50"></div>
              <div className="absolute bottom-0 left-0 w-32 h-32 bg-purple-100 opacity-50"></div>

              <div className="p-12 md:p-16 relative z-10">
                <div className="text-center mb-12">
                  <h2 className="text-4xl font-bold text-gray-800 mb-4">
                    Welcome to Byrah
                  </h2>
                  <p className="text-gray-600 text-lg">
                    Sign in to manage your links and profile
                  </p>
                </div>

                <div className="space-y-8">
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-600 transform -rotate-1 opacity-10"></div>
                    <button
                      onClick={handleSignUp}
                      disabled={isLoading}
                      className="relative w-full py-5 bg-white border-2 border-gray-200 hover:border-indigo-500 flex items-center justify-center space-x-4 transition-all duration-300 group hover:shadow-lg"
                    >
                      <img
                        src="https://www.google.com/favicon.ico"
                        alt="Google"
                        className="w-7 h-7"
                      />
                      <span className="text-gray-700 font-medium text-lg">
                        {isLoading ? "Connecting..." : "Continue with Google"}
                      </span>
                      <ArrowRight className="w-6 h-6 text-indigo-500 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all duration-300" />
                    </button>
                  </div>
                </div>

                <div className="mt-12 text-center text-sm text-gray-500">
                  <p>By signing in, you agree to our</p>
                  <div className="mt-2 space-x-1">
                    <a
                      href="/terms"
                      className="text-indigo-600 hover:text-indigo-800 hover:underline"
                    >
                      Terms of Service
                    </a>
                    <span>&</span>
                    <a
                      href="/privacy"
                      className="text-indigo-600 hover:text-indigo-800 hover:underline"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Features Section */}
        <div
          className={`md:hidden mt-16 px-4 text-white space-y-8 transition-all duration-1000 ${
            animateIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          }`}
        >
          <h3 className="text-2xl font-semibold mb-6 text-center">
            Why choose Byrah?
          </h3>

          <div className="flex items-start space-x-4">
            <div className="bg-indigo-600/30 p-3 rounded-lg">
              <Globe className="h-6 w-6 text-indigo-300" />
            </div>
            <div>
              <h3 className="font-semibold text-lg">One link for everything</h3>
              <p className="text-indigo-200 text-base mt-1">
                Share your content, social profiles, and more
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-4">
            <div className="bg-indigo-600/30 p-3 rounded-lg">
              <Sparkles className="h-6 w-6 text-indigo-300" />
            </div>
            <div>
              <h3 className="font-semibold text-lg">Customizable profile</h3>
              <p className="text-indigo-200 text-base mt-1">
                Make your page match your brand
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-4">
            <div className="bg-indigo-600/30 p-3 rounded-lg">
              <CheckCircle className="h-6 w-6 text-indigo-300" />
            </div>
            <div>
              <h3 className="font-semibold text-lg">Detailed analytics</h3>
              <p className="text-indigo-200 text-base mt-1">
                Track clicks and optimize your content
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Animated background elements */}
      <div className="fixed top-0 left-0 w-full h-full pointer-events-none overflow-hidden -z-10">
        <div className="absolute top-[10%] left-[5%] w-80 h-80 bg-indigo-600 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-[20%] right-[10%] w-96 h-96 bg-purple-600 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute bottom-[10%] left-[20%] w-96 h-96 bg-pink-600 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-4000"></div>
      </div>
    </div>
  );
};

export default Login;
