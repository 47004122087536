"use client"

import { Check, Copy, Globe } from "lucide-react"
import { useState } from "react"

interface ProfileUrlBarProps {
  username: string
}

export function ProfileUrlBar({ username }: ProfileUrlBarProps) {
  const [copied, setCopied] = useState(false)

  const copyProfileUrl = () => {
    if (!username) return

    const url = `${window.location.origin}/u/${username}`
    navigator.clipboard.writeText(url)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  if (!username) return null

  return (
    <div className="bg-white p-4 mb-6 rounded-lg shadow-sm flex flex-col sm:flex-row items-center justify-between border-l-4 border-indigo-500">
      <div className="flex items-center mb-3 sm:mb-0">
        <Globe className="h-5 w-5 text-indigo-500 mr-2" />
        <span className="text-gray-700 font-medium">Your profile:</span>
        <a
          href={`${window.location.origin}/u/${username}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 text-indigo-600 hover:text-indigo-800 transition-colors"
        >
          {window.location.origin}/u/{username}
        </a>
      </div>
      <button
        onClick={copyProfileUrl}
        className={`px-4 py-2 flex items-center text-sm font-medium rounded-md transition-all ${
          copied ? "bg-green-100 text-green-700" : "bg-indigo-100 text-indigo-700 hover:bg-indigo-200"
        }`}
      >
        {copied ? (
          <>
            <Check className="h-4 w-4 mr-1.5" />
            Copied!
          </>
        ) : (
          <>
            <Copy className="h-4 w-4 mr-1.5" />
            Copy URL
          </>
        )}
      </button>
    </div>
  )
}

