"use client";
import { motion } from "framer-motion";
import { FaStar, FaPalette, FaShareAlt, FaChartBar } from "react-icons/fa";

const FeaturesSection = () => {
  const features = [
    {
      icon: <FaStar className="h-6 w-6" />,
      title: "Beautiful Profiles",
      description:
        "Create stunning, customizable profiles that showcase your personality and brand.",
    },
    {
      icon: <FaPalette className="h-6 w-6" />,
      title: "Easy Customization",
      description:
        "Change colors, layouts, and styles with our intuitive design tools.",
    },
    {
      icon: <FaShareAlt className="h-6 w-6" />,
      title: "One Link for All",
      description:
        "Share all your social profiles and content with just one simple link.",
    },
    {
      icon: <FaChartBar className="h-6 w-6" />,
      title: "Detailed Analytics",
      description:
        "Track visitors, clicks, and engagement to optimize your online presence.",
    },
  ];

  return (
    <section
      id="features"
      className="py-24 bg-gradient-to-br from-cyan-50 to-purple-50"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl text-purple-700">
            Why Choose Byrah?
          </h2>
          <p className="mt-4 text-xl text-purple-600 max-w-2xl mx-auto">
            The most powerful and flexible platform for creating your perfect
            link in bio page.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <div className="h-full bg-white rounded-lg border border-purple-200 shadow-md hover:shadow-lg transition-shadow p-6">
                <div className="flex flex-col items-center text-center">
                  <div
                    className={`h-12 w-12 rounded-full ${
                      index === 0
                        ? "bg-pink-500/20 text-pink-600"
                        : index === 1
                        ? "bg-purple-500/20 text-purple-600"
                        : index === 2
                        ? "bg-cyan-500/20 text-cyan-600"
                        : "bg-orange-500/20 text-orange-600"
                    } flex items-center justify-center mb-4`}
                  >
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-medium mb-2 text-purple-700">
                    {feature.title}
                  </h3>
                  <p className="text-purple-600">{feature.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
