"use client";

import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import { motion } from "framer-motion";

const HeroSection = () => {
  const typingRef = useRef(null);

  useEffect(() => {
    if (!typingRef.current) return;

    const typed = new Typed(typingRef.current, {
      strings: [
        "byrah.com/u/USERNAME",
        "Best link in bio tool",
        "Share all your links in one place",
      ],
      typeSpeed: 50,
      backSpeed: 30,
      startDelay: 500,
      backDelay: 2000,
      loop: true,
      showCursor: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-[#ccff90]/50 to-white py-24 md:py-32">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e')] bg-center [mask-image:linear-gradient(to_bottom,white,transparent)]" />

      <div className="container relative mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          className="mx-auto max-w-3xl text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-5xl font-bold tracking-tight sm:text-6xl md:text-7xl">
            Your ultimate <span className="text-[#558b2f]">link in bio</span>{" "}
            tool
          </h1>

          <div className="mt-6 h-8 text-xl text-gray-600">
            <span ref={typingRef}></span>
          </div>

          <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link
              to="/login"
              className="px-8 py-3 bg-black text-white rounded-md hover:bg-black/80 transition-colors text-lg font-medium"
            >
              Get Started for Free
            </Link>
            <button className="px-8 py-3 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors text-lg font-medium">
              See Examples
            </button>
          </div>

          <motion.div
            className="mt-16 flex justify-center"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <div className="relative w-full max-w-md overflow-hidden rounded-xl border shadow-xl bg-white">
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <div className="h-10 w-10 rounded-full bg-[#ccff90]"></div>
                    <div>
                      <div className="font-medium">@username</div>
                      <div className="text-xs text-gray-500">
                        Digital Creator
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 space-y-3">
                  {[1, 2, 3].map((i) => (
                    <div
                      key={i}
                      className="h-12 rounded-md bg-gray-100 flex items-center justify-center"
                    >
                      <span className="text-sm text-gray-500">Link {i}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;
