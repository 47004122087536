"use client";

import type React from "react";

import { User } from "lucide-react";
import type { Profile } from "../../types";

interface ProfilePictureProps {
  profile: Profile | null;
  handlePictureUpload: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
}

export function ProfilePicture({
  profile,
  handlePictureUpload,
}: ProfilePictureProps) {
  return (
    <div className="flex flex-col sm:flex-row items-center gap-6 mb-4">
      <div className="w-24 h-24 rounded-full overflow-hidden bg-indigo-100 flex items-center justify-center border-2 border-indigo-200">
        {profile?.picture ? (
          <img
            src={profile.picture || "/placeholder.svg"}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        ) : (
          <User className="h-12 w-12 text-indigo-300" />
        )}
      </div>

      <div className="flex-1">
        <p className="text-gray-600 mb-2">
          Upload a new profile picture. Square images work best.
        </p>
        <label className="block">
          <span className="sr-only">Choose profile photo</span>
          <input
            type="file"
            accept="image/*"
            onChange={handlePictureUpload}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100 transition-colors"
          />
        </label>
      </div>
    </div>
  );
}
