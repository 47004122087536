import type { Link, Profile } from "../../types";
import PublicProfile from "../../../components/Profile/PublicProfile";

interface ProfilePreviewProps {
  profile: Profile | null;
  backgroundColor: string;
  backgroundType: "solid" | "gradient" | "pattern";
  gradientDirection: string;
  gradientColors: [string, string];
  patternSelection: string;
  links: Link[];
  username: string;
  bio: string;
  fetchProfileAndLinks: () => Promise<void>;
}

export function ProfilePreview({
  profile,
  backgroundColor,
  backgroundType,
  gradientDirection,
  gradientColors,
  patternSelection,
  links,
  username,
  bio,
  fetchProfileAndLinks,
}: ProfilePreviewProps) {
  const getBackgroundStyle = () => {
    if (backgroundType === "solid") {
      return backgroundColor;
    } else if (backgroundType === "gradient") {
      if (gradientDirection === "circle") {
        return `radial-gradient(circle, ${gradientColors[0]}, ${gradientColors[1]})`;
      }
      return `linear-gradient(${gradientDirection}, ${gradientColors[0]}, ${gradientColors[1]})`;
    } else if (backgroundType === "pattern") {
      return `pattern:${patternSelection}`;
    }
    return backgroundColor;
  };

  const previewData = {
    name: profile?.name || "User",
    picture: profile?.picture || "",
    background_color: getBackgroundStyle(),
    links: links || [],
    username: username || profile?.username || "",
    bio: bio || profile?.bio || "",
  };
  console.log("ProfilePreview previewData:", previewData);

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Live Preview</h2>
      <div className="overflow-hidden rounded-lg border border-gray-200">
        {profile ? (
          <div className="transform scale-[0.85] origin-top">
            <PublicProfile
              previewData={previewData}
              onLinkClick={fetchProfileAndLinks}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-64">
            <p className="text-gray-500">Loading preview...</p>
          </div>
        )}
      </div>
    </div>
  );
}
