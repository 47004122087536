import type React from "react";
import Navbar from "../components/Navbar/Navbar";

const Privacy: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-50 py-10 px-4">
        <div className="max-w-3xl mx-auto bg-white p-8 shadow-md">
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
              <p className="text-gray-700">
                This Privacy Policy explains how we collect, use, and disclose
                information about you when you use our services.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">
                2. Information We Collect
              </h2>
              <p className="text-gray-700">
                We collect information you provide directly to us when you
                create an account, update your profile, or add links. This
                includes your name, email address, profile picture, and any
                other information you choose to provide.
              </p>
              <p className="text-gray-700 mt-2">
                We also automatically collect certain information when you use
                our service, including log data, device information, and usage
                information.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">
                3. How We Use Your Information
              </h2>
              <p className="text-gray-700">
                We use the information we collect to provide, maintain, and
                improve our services, to communicate with you, and to
                personalize your experience.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">
                4. Sharing Your Information
              </h2>
              <p className="text-gray-700">
                We do not share your personal information with third parties
                except as described in this Privacy Policy. We may share
                information with service providers who perform services on our
                behalf, when required by law, or in connection with a merger or
                acquisition.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">5. Your Choices</h2>
              <p className="text-gray-700">
                You can access and update certain information about you from
                within your account settings. You can also request deletion of
                your account at any time.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">6. Security</h2>
              <p className="text-gray-700">
                We take reasonable measures to help protect your personal
                information from loss, theft, misuse, unauthorized access,
                disclosure, alteration, and destruction.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">
                7. Changes to this Policy
              </h2>
              <p className="text-gray-700">
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">8. Contact Us</h2>
              <p className="text-gray-700">
                If you have any questions about this Privacy Policy, please
                contact us.
              </p>
            </section>
          </div>

          <div className="mt-8 text-sm text-gray-500">
            Last updated: March 23, 2025
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
