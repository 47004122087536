import type React from "react";
import type { Link, Profile } from "../../types";
import { LinkForm } from "./LinkForm";
import { LinksList } from "./LinksList";
import { LinksPreview } from "./LinksPreview";
import { LinksPerformance } from "./LinksPerformance";

interface LinksManagementProps {
  profile: Profile | null;
  links: Link[];
  isAddingLink: boolean;
  editId: string | null;
  title: string;
  url: string;
  buttonColor: string;
  socialIcon: string;
  thumbnailFile: File | null;
  backgroundColor: string;
  username: string;
  bio: string;
  setIsAddingLink: (isAdding: boolean) => void;
  setEditId: (id: string | null) => void;
  setTitle: (title: string) => void;
  setUrl: (url: string) => void;
  setButtonColor: (color: string) => void;
  setSocialIcon: (icon: string) => void;
  setThumbnailFile: (file: File | null) => void;
  handleAddLink: (e: React.FormEvent) => Promise<void>;
  handleEditLink: (link: Link) => Promise<void>;
  handleUpdateLink: (e: React.FormEvent) => Promise<void>;
  handleDeleteLink: (id: string) => Promise<void>;
  handleMoveLink: (id: string, direction: "up" | "down") => Promise<void>;
  fetchProfileAndLinks: () => Promise<void>;
  getContrastColor: (hexColor: string) => string;
  socialIcons: { value: string; label: string }[];
}

export function LinksManagement({
  profile,
  links,
  isAddingLink,
  editId,
  title,
  url,
  buttonColor,
  socialIcon,
  thumbnailFile,
  backgroundColor,
  username,
  bio,
  setIsAddingLink,
  setEditId,
  setTitle,
  setUrl,
  setButtonColor,
  setSocialIcon,
  setThumbnailFile,
  handleAddLink,
  handleEditLink,
  handleUpdateLink,
  handleDeleteLink,
  handleMoveLink,
  fetchProfileAndLinks,
  getContrastColor,
  socialIcons,
}: LinksManagementProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2 space-y-6">
        {/* Add/Edit Link Form */}
        {isAddingLink && (
          <LinkForm
            editId={editId}
            title={title}
            url={url}
            buttonColor={buttonColor}
            socialIcon={socialIcon}
            thumbnailFile={thumbnailFile}
            setIsAddingLink={setIsAddingLink}
            setEditId={setEditId}
            setTitle={setTitle}
            setUrl={setUrl}
            setButtonColor={setButtonColor}
            setSocialIcon={setSocialIcon}
            setThumbnailFile={setThumbnailFile}
            handleAddLink={handleAddLink}
            handleUpdateLink={handleUpdateLink}
            getContrastColor={getContrastColor}
            socialIcons={socialIcons}
          />
        )}

        {/* Links List */}
        <LinksList
          links={links}
          isAddingLink={isAddingLink}
          setIsAddingLink={setIsAddingLink}
          handleEditLink={handleEditLink}
          handleDeleteLink={handleDeleteLink}
          handleMoveLink={handleMoveLink}
          getContrastColor={getContrastColor}
        />
      </div>

      {/* Preview */}
      <div className="space-y-6">
        <LinksPreview
          profile={profile}
          backgroundColor={backgroundColor}
          links={links}
          username={username}
          bio={bio}
          fetchProfileAndLinks={fetchProfileAndLinks}
        />
        <LinksPerformance links={links} />
      </div>
    </div>
  );
}
