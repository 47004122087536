"use client";

import { Bell, Layers, Search, User } from "lucide-react";

interface DashboardHeaderProps {
  profileName: string;
  profilePicture?: string;
  toggleSidebar: () => void;
}

export function DashboardHeader({
  profileName,
  profilePicture,
  toggleSidebar,
}: DashboardHeaderProps) {
  return (
    <header className="bg-white border-b border-gray-200 sticky top-0 z-10">
      <div className="max-w-[1600px] mx-auto px-4 flex items-center justify-between h-16">
        <div className="flex items-center">
          <button
            onClick={toggleSidebar}
            className="p-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 lg:hidden"
          >
            <Layers className="h-5 w-5" />
          </button>
          <div className="ml-4 lg:ml-0 font-bold text-xl text-indigo-600">
            Byrah
          </div>
        </div>

        <div className="flex items-center gap-2 md:gap-4">
          <button className="p-2 rounded-full text-gray-500 hover:text-gray-700 hover:bg-gray-100 relative">
            <Bell className="h-5 w-5" />
            <span className="absolute top-1 right-1 h-2 w-2 bg-red-500 rounded-full"></span>
          </button>

          <div className="flex items-center gap-2">
            <div className="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center overflow-hidden">
              {profilePicture ? (
                <img
                  src={profilePicture || "/placeholder.svg"}
                  alt="Profile"
                  className="h-full w-full object-cover"
                />
              ) : (
                <User className="h-4 w-4 text-indigo-600" />
              )}
            </div>
            <span className="text-sm font-medium text-gray-700 hidden md:block">
              {profileName || "User"}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}
