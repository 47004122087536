"use client";

import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("AuthCallback mounted, location:", location.search);
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!token) {
      setError("Missing token parameter");
      setIsLoading(false);
      console.error("No token in URL query");
      return;
    }

    console.log("Sending OAuth token to backend:", token);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/verify`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ accessToken: token }),
      credentials: "include",
    })
      .then(async (response) => {
        console.log("Backend response status:", response.status);
        if (!response.ok) {
          const data = await response.json();
          setError(
            `Authentication failed: ${data.error || "Unknown error"} - ${
              data.details || "No details"
            }`
          );
          console.error("Backend response error:", data);
          setIsLoading(false);
          return;
        }

        // Authentication successful, redirect to dashboard
        console.log("Authentication successful, redirecting to dashboard");
        navigate("/dashboard");
        setIsLoading(false);
      })
      .catch((fetchError) => {
        setError("Network error: " + fetchError.message);
        console.error("Fetch error details:", fetchError);
        setIsLoading(false);
      });
  }, [navigate, location]);

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
        <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
          <h1 className="text-2xl font-bold text-red-600 mb-4">
            Authentication Error
          </h1>
          <p className="text-gray-700 mb-6">{error}</p>
          <div className="flex gap-4">
            <button
              onClick={() => navigate("/login")}
              className="py-2 px-4 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
            >
              Back to Login
            </button>
            <button
              onClick={() => window.location.reload()}
              className="py-2 px-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Retry
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full text-center">
        <div className="animate-spin h-12 w-12 border-b-2 border-indigo-600 rounded-full mx-auto mb-4"></div>
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Authenticating...
        </h2>
        <p className="text-gray-600">
          Please wait while we complete your sign-in
        </p>
      </div>
    </div>
  );
};

export default AuthCallback;
