"use client";

import type React from "react";
import { useState } from "react";
import { Check, ChevronDown } from "lucide-react";

interface ProfileColorPickerProps {
  backgroundColor: string;
  handleColorChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setBackgroundColor: (color: string) => void;
  backgroundType: "solid" | "gradient" | "pattern";
  setBackgroundType: (type: "solid" | "gradient" | "pattern") => void;
  gradientDirection: string;
  setGradientDirection: (direction: string) => void;
  gradientColors: [string, string];
  setGradientColors: (colors: [string, string]) => void;
  patternSelection: string;
  setPatternSelection: (pattern: string) => void;
  handleBackgroundUpdate: () => Promise<void>;
}

export function ProfileColorPicker({
  backgroundColor,
  handleColorChange,
  setBackgroundColor,
  backgroundType,
  setBackgroundType,
  gradientDirection,
  setGradientDirection,
  gradientColors,
  setGradientColors,
  patternSelection,
  setPatternSelection,
  handleBackgroundUpdate,
}: ProfileColorPickerProps) {
  const [isGradientMenuOpen, setIsGradientMenuOpen] = useState(false);
  const [isPatternMenuOpen, setIsPatternMenuOpen] = useState(false);

  // Predefined gradients
  const gradientDirections = [
    { value: "to right", label: "Horizontal" },
    { value: "to bottom", label: "Vertical" },
    { value: "to bottom right", label: "Diagonal ↘" },
    { value: "to bottom left", label: "Diagonal ↙" },
    { value: "circle", label: "Radial" },
  ];

  // Predefined patterns
  const patterns = [
    {
      value: "pattern-dots",
      label: "Dots",
      preview:
        "bg-gray-100 bg-[radial-gradient(black_1px,transparent_1px)] bg-[size:20px_20px]",
    },
    {
      value: "pattern-grid",
      label: "Grid",
      preview:
        "bg-gray-100 bg-[linear-gradient(to_right,#8882_1px,transparent_1px),linear-gradient(to_bottom,#8882_1px,transparent_1px)] bg-[size:20px_20px]",
    },
    {
      value: "pattern-waves",
      label: "Waves",
      preview:
        "bg-blue-50 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAwIDUwYzAgNTAgMTAwIDUwIDEwMCAxMDBzLTEwMCA1MC0xMDAgMTAwIDAtNTAgMC0xMDAgLTEwMC01MCAtMTAwLTEwMCAxMDAtNTAgMTAwLTUweiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjM2NmYxMjAiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==')]",
    },
    {
      value: "pattern-zigzag",
      label: "Zigzag",
      preview:
        "bg-gray-100 bg-[linear-gradient(135deg,#88888822_25%,transparent_25%),linear-gradient(225deg,#88888822_25%,transparent_25%),linear-gradient(315deg,#88888822_25%,transparent_25%),linear-gradient(45deg,#88888822_25%,transparent_25%)] bg-[size:20px_20px]",
    },
    {
      value: "pattern-stripes",
      label: "Stripes",
      preview:
        "bg-gray-100 bg-[linear-gradient(135deg,#88888822_10%,transparent_0,transparent_50%,#88888822_0,#88888822_60%,transparent_0,transparent)] bg-[size:15px_15px]",
    },
  ];

  const handleGradientColorChange = (index: 0 | 1, color: string) => {
    const newColors = [...gradientColors] as [string, string];
    newColors[index] = color;
    setGradientColors(newColors);
  };

  const getGradientPreview = () => {
    if (gradientDirection === "circle") {
      return `radial-gradient(circle, ${gradientColors[0]}, ${gradientColors[1]})`;
    }
    return `linear-gradient(${gradientDirection}, ${gradientColors[0]}, ${gradientColors[1]})`;
  };

  const getPatternStyle = () => {
    const pattern = patterns.find((p) => p.value === patternSelection);
    if (!pattern) return {};

    const bgClasses = pattern.preview.split(" ");
    const bgColor = bgClasses[0];
    const bgImage = bgClasses.find((c) => c.startsWith("bg-["));
    const bgSize = bgClasses.find((c) => c.startsWith("bg-[size:"));

    return {
      backgroundColor:
        bgColor === "bg-gray-100"
          ? "#f3f4f6"
          : bgColor === "bg-blue-50"
          ? "#eff6ff"
          : "#f3f4f6",
      backgroundImage: bgImage
        ? bgImage.replace("bg-[", "").replace("]", "")
        : "",
      backgroundSize: bgSize
        ? bgSize.replace("bg-[size:", "").replace("]", "")
        : "",
    };
  };

  return (
    <div className="space-y-6">
      {/* Background Type Selector */}
      <div className="flex space-x-2 mb-4">
        <button
          onClick={() => setBackgroundType("solid")}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            backgroundType === "solid"
              ? "bg-indigo-100 text-indigo-700"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          }`}
        >
          Solid Color
        </button>
        <button
          onClick={() => setBackgroundType("gradient")}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            backgroundType === "gradient"
              ? "bg-indigo-100 text-indigo-700"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          }`}
        >
          Gradient
        </button>
        <button
          onClick={() => setBackgroundType("pattern")}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            backgroundType === "pattern"
              ? "bg-indigo-100 text-indigo-700"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          }`}
        >
          Pattern
        </button>
      </div>

      {/* Solid Color Picker */}
      {backgroundType === "solid" && (
        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <input
              type="color"
              value={backgroundColor}
              onChange={handleColorChange}
              className="w-16 h-16 cursor-pointer border-0 rounded"
            />
            <div className="flex-1">
              <p className="text-gray-600 mb-1">
                Choose a background color for your profile page
              </p>
              <p className="text-sm font-mono text-indigo-600">
                {backgroundColor}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-6 gap-2">
            {[
              "#6366f1", // Indigo
              "#8b5cf6", // Violet
              "#ec4899", // Pink
              "#f43f5e", // Rose
              "#ef4444", // Red
              "#f97316", // Orange
              "#f59e0b", // Amber
              "#10b981", // Emerald
              "#06b6d4", // Cyan
              "#000000", // Black
            ].map((color) => (
              <button
                key={color}
                onClick={() => setBackgroundColor(color)}
                className="w-full aspect-square rounded-md transition-all hover:scale-110"
                style={{
                  backgroundColor: color,
                  outline:
                    backgroundColor === color
                      ? "2px solid rgb(99, 102, 241)"
                      : "none",
                  outlineOffset: "2px",
                }}
                aria-label={`Set background color to ${color}`}
              />
            ))}
          </div>

          {/* New Apply Colour Button */}
          <button
            onClick={handleBackgroundUpdate}
            className="w-full py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition-all duration-200"
          >
            Apply Colour
          </button>
        </div>
      )}

      {/* Gradient Picker */}
      {backgroundType === "gradient" && (
        <div className="space-y-4">
          <div className="flex flex-col space-y-4">
            <div
              className="w-full h-24 rounded-lg"
              style={{ background: getGradientPreview() }}
            ></div>

            <div className="relative">
              <button
                onClick={() => setIsGradientMenuOpen(!isGradientMenuOpen)}
                className="w-full flex items-center justify-between px-4 py-2 border border-gray-300 rounded-lg bg-white"
              >
                <span>
                  Direction:{" "}
                  {
                    gradientDirections.find(
                      (d) => d.value === gradientDirection
                    )?.label
                  }
                </span>
                <ChevronDown className="h-4 w-4 text-gray-500" />
              </button>

              {isGradientMenuOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg">
                  {gradientDirections.map((direction) => (
                    <button
                      key={direction.value}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center justify-between"
                      onClick={() => {
                        setGradientDirection(direction.value);
                        setIsGradientMenuOpen(false);
                      }}
                    >
                      {direction.label}
                      {gradientDirection === direction.value && (
                        <Check className="h-4 w-4 text-indigo-600" />
                      )}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Color
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="color"
                    value={gradientColors[0]}
                    onChange={(e) =>
                      handleGradientColorChange(0, e.target.value)
                    }
                    className="w-12 h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <span className="text-sm font-mono text-gray-600">
                    {gradientColors[0]}
                  </span>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Color
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="color"
                    value={gradientColors[1]}
                    onChange={(e) =>
                      handleGradientColorChange(1, e.target.value)
                    }
                    className="w-12 h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <span className="text-sm font-mono text-gray-600">
                    {gradientColors[1]}
                  </span>
                </div>
              </div>
            </div>

            <button
              onClick={handleBackgroundUpdate}
              className="w-full py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition-all duration-200"
            >
              Apply Gradient
            </button>
          </div>
        </div>
      )}

      {/* Pattern Picker */}
      {backgroundType === "pattern" && (
        <div className="space-y-4">
          <div className="relative">
            <button
              onClick={() => setIsPatternMenuOpen(!isPatternMenuOpen)}
              className="w-full flex items-center justify-between px-4 py-2 border border-gray-300 rounded-lg bg-white"
            >
              <span>
                Pattern:{" "}
                {patterns.find((p) => p.value === patternSelection)?.label ||
                  "Select a pattern"}
              </span>
              <ChevronDown className="h-4 w-4 text-gray-500" />
            </button>

            {isPatternMenuOpen && (
              <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                {patterns.map((pattern) => (
                  <button
                    key={pattern.value}
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center justify-between"
                    onClick={() => {
                      setPatternSelection(pattern.value);
                      setIsPatternMenuOpen(false);
                    }}
                  >
                    <div className="flex items-center">
                      <div
                        className={`w-8 h-8 rounded mr-2 ${pattern.preview}`}
                      ></div>
                      {pattern.label}
                    </div>
                    {patternSelection === pattern.value && (
                      <Check className="h-4 w-4 text-indigo-600" />
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>

          {patternSelection && (
            <div
              className="w-full h-24 rounded-lg border border-gray-200"
              style={getPatternStyle()}
            ></div>
          )}

          <button
            onClick={handleBackgroundUpdate}
            className="w-full py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition-all duration-200"
          >
            Apply Pattern
          </button>
        </div>
      )}
    </div>
  );
}
