"use client";

import type React from "react";
import { motion } from "framer-motion";
import { FaUserPlus, FaPaintBrush, FaShareAlt } from "react-icons/fa";

interface Step {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const SetupSteps: React.FC = () => {
  const steps: Step[] = [
    {
      icon: <FaUserPlus className="h-8 w-8" />,
      title: "Sign Up",
      description: "Create your account with email, username, and password",
    },
    {
      icon: <FaPaintBrush className="h-8 w-8" />,
      title: "Customize",
      description:
        "Design your profile with your links, bio, and style preferences",
    },
    {
      icon: <FaShareAlt className="h-8 w-8" />,
      title: "Share",
      description: "Share your unique Byrah link with your audience",
    },
  ];

  return (
    <section
      id="setup"
      className="py-24 bg-gradient-to-b from-pink-50 to-purple-50"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl text-purple-700">
            3 Easy Steps to Get Started
          </h2>
          <p className="mt-4 text-xl text-purple-600 max-w-2xl mx-auto">
            Setting up your Byrah profile is quick and simple
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <div
                className={`h-full border rounded-lg shadow-md ${
                  index === 0
                    ? "bg-gradient-to-b from-white to-pink-50 border-pink-200"
                    : index === 1
                    ? "bg-gradient-to-b from-white to-purple-50 border-purple-200"
                    : "bg-gradient-to-b from-white to-cyan-50 border-cyan-200"
                }`}
              >
                <div className="p-8 flex flex-col items-center text-center">
                  <div className="relative">
                    <div
                      className={`absolute -inset-3 rounded-full ${
                        index === 0
                          ? "bg-pink-400/30"
                          : index === 1
                          ? "bg-purple-400/30"
                          : "bg-cyan-400/30"
                      } animate-pulse`}
                    ></div>
                    <div
                      className={`relative h-16 w-16 rounded-full ${
                        index === 0
                          ? "bg-pink-500/50 text-pink-700"
                          : index === 1
                          ? "bg-purple-500/50 text-purple-700"
                          : "bg-cyan-500/50 text-cyan-700"
                      } flex items-center justify-center mb-6`}
                    >
                      {step.icon}
                      <div
                        className={`absolute -top-2 -right-2 h-8 w-8 rounded-full ${
                          index === 0
                            ? "bg-pink-600"
                            : index === 1
                            ? "bg-purple-600"
                            : "bg-cyan-600"
                        } flex items-center justify-center text-white font-bold`}
                      >
                        {index + 1}
                      </div>
                    </div>
                  </div>
                  <h3
                    className={`text-2xl font-medium mb-3 ${
                      index === 0
                        ? "text-pink-700"
                        : index === 1
                        ? "text-purple-700"
                        : "text-cyan-700"
                    }`}
                  >
                    {step.title}
                  </h3>
                  <p
                    className={`${
                      index === 0
                        ? "text-pink-600"
                        : index === 1
                        ? "text-purple-600"
                        : "text-cyan-600"
                    }`}
                  >
                    {step.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SetupSteps;
