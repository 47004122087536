"use client";

import type React from "react";
import { useState, useEffect } from "react";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaWhatsapp,
  FaSnapchat,
  FaTiktok,
} from "react-icons/fa";

interface SocialLink {
  icon: React.ReactNode;
  name: string;
  color: string;
}

const SocialLinks: React.FC = () => {
  const allLinks: SocialLink[] = [
    {
      icon: <FaInstagram className="h-6 w-6" />,
      name: "Instagram",
      color: "bg-gradient-to-r from-purple-600 to-pink-500",
    },
    {
      icon: <FaFacebook className="h-6 w-6" />,
      name: "Facebook",
      color: "bg-gradient-to-r from-blue-600 to-blue-500",
    },
    {
      icon: <FaTwitter className="h-6 w-6" />,
      name: "Twitter",
      color: "bg-gradient-to-r from-cyan-500 to-blue-400",
    },
    {
      icon: <FaLinkedin className="h-6 w-6" />,
      name: "LinkedIn",
      color: "bg-gradient-to-r from-blue-700 to-blue-500",
    },
    {
      icon: <FaYoutube className="h-6 w-6" />,
      name: "YouTube",
      color: "bg-gradient-to-r from-red-600 to-red-500",
    },
    {
      icon: <FaWhatsapp className="h-6 w-6" />,
      name: "WhatsApp",
      color: "bg-gradient-to-r from-green-600 to-green-500",
    },
    {
      icon: <FaSnapchat className="h-6 w-6 text-black" />,
      name: "Snapchat",
      color: "bg-gradient-to-r from-yellow-400 to-yellow-300",
    },
    {
      icon: <FaTiktok className="h-6 w-6" />,
      name: "TikTok",
      color: "bg-gradient-to-r from-black to-gray-800",
    },
  ];

  const [visibleLinks, setVisibleLinks] = useState<SocialLink[]>(
    allLinks.slice(0, 4)
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const totalPages = Math.ceil(allLinks.length / 4);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPage((prev) => (prev + 1) % totalPages);
    }, 3000);

    return () => clearInterval(interval);
  }, [totalPages]);

  useEffect(() => {
    setVisibleLinks(allLinks.slice(currentPage * 4, (currentPage + 1) * 4));
  }, [currentPage]);

  return (
    <section
      id="social"
      className="py-24 bg-gradient-to-b from-purple-100 to-pink-50"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl text-purple-700">
            Connect All Your Platforms
          </h2>
          <p className="mt-4 text-xl text-purple-600 max-w-2xl mx-auto">
            Byrah supports all major social media platforms and more
          </p>
        </div>

        <div className="relative h-[320px] max-w-4xl mx-auto">
          <div
            key={currentPage}
            className="grid grid-cols-2 md:grid-cols-4 gap-6 opacity-0 transition-opacity duration-300 ease-in-out"
            style={{ animation: "fadeIn 0.3s forwards" }}
          >
            {visibleLinks.map((link, i) => (
              <div
                key={`${currentPage}-${i}`}
                className={`${link.color} rounded-lg shadow-lg p-6 flex flex-col items-center justify-center text-white h-[140px] cursor-pointer transform transition-all duration-300 ease-out hover:scale-105 opacity-0 translate-y-5`}
                style={{
                  animation: `slideUp 0.3s ease-out ${i * 0.1}s forwards`,
                }}
              >
                <div className="mb-3">{link.icon}</div>
                <span className="font-medium">{link.name}</span>
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-8 space-x-2">
            {Array.from({ length: totalPages }).map((_, i) => (
              <button
                key={i}
                className={`h-2 w-2 rounded-full transition-colors ${
                  currentPage === i ? "bg-purple-600" : "bg-gray-300"
                }`}
                onClick={() => setCurrentPage(i)}
              />
            ))}
          </div>
        </div>
      </div>

      <style>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        @keyframes slideUp {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
    </section>
  );
};

export default SocialLinks;
