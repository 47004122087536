import { ArrowUpRight, ChevronRight, LinkIcon } from "lucide-react";
import type { Link } from "../../types";

interface LinksPerformanceProps {
  links: Link[];
}
//
export function LinksPerformance({ links }: LinksPerformanceProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">
        Link Performance
      </h2>
      <div className="space-y-4">
        {links.length > 0 ? (
          links.slice(0, 3).map((link) => (
            <div key={link.id} className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="h-8 w-8 flex items-center justify-center mr-3 flex-shrink-0 rounded-md"
                  style={{
                    backgroundColor: link.button_color || "#000000",
                    color: link.button_color
                      ? Number.parseInt(link.button_color.slice(1), 16) >
                        0xffffff / 2
                        ? "#000000"
                        : "#ffffff"
                      : "#ffffff",
                  }}
                >
                  {link.social_icon ? (
                    <img
                      src={`https://cdn.jsdelivr.net/npm/simple-icons@v9/icons/${link.social_icon}.svg`}
                      alt={`${link.title} icon`}
                      className="w-4 h-4 object-contain"
                      style={{
                        filter:
                          link.button_color &&
                          Number.parseInt(link.button_color.slice(1), 16) >
                            0xffffff / 2
                            ? "none"
                            : "invert(1)",
                      }}
                    />
                  ) : (
                    <LinkIcon className="w-4 h-4" />
                  )}
                </div>
                <span className="text-sm font-medium text-gray-700 truncate max-w-[120px]">
                  {link.title}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-900">
                  {link.click_count || 0}
                </span>
                <span className="text-xs text-gray-500 ml-1">clicks</span>
                <ArrowUpRight className="h-4 w-4 text-green-500 ml-2" />
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-sm">
            Add links to see performance metrics
          </p>
        )}

        {links.length > 3 && (
          <a
            href="#"
            className="text-indigo-600 text-sm font-medium flex items-center hover:text-indigo-800"
          >
            View all link statistics
            <ChevronRight className="h-4 w-4 ml-1" />
          </a>
        )}
      </div>
    </div>
  );
}
