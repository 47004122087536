import Navbar from "./Navbar/Navbar";
import HeroSection from "./HeroSection";
import FeaturesSection from "./FeaturesSection";
import SetupSteps from "./SetupSteps";
import SocialLinks from "./SocialLinks";
import Footer from "./Footer";
import AnalyticsSection from "./AnalyticsSection";

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Navbar />
      <main className="flex-1">
        <HeroSection />
        <FeaturesSection />
        <SetupSteps />
        <SocialLinks />
        <AnalyticsSection />
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
