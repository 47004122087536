"use client";

import {
  BarChart3,
  Layers,
  LinkIcon,
  LogOut,
  MessageCircle,
  Settings,
  Sparkles,
  User,
  X,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

interface DashboardSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  activeTab: "profile" | "links" | "analytics";
  setActiveTab: (tab: "profile" | "links" | "analytics") => void;
}

export function DashboardSidebar({
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
}: DashboardSidebarProps) {
  const navigate = useNavigate();

  // Helper function to handle tab changes and close sidebar on mobile
  const handleTabChange = (tab: "profile" | "links" | "analytics") => {
    setActiveTab(tab);
    // On mobile, close the sidebar after selection
    if (window.innerWidth < 1024) {
      onClose();
    }
  };

  // Handle sign out
  const handleSignOut = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/signout`, // Correct endpoint
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (response.ok) {
        // Redirect to login page after successful logout
        navigate("/login");
      } else {
        console.error("Failed to sign out:", await response.json());
      }
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <>
      {/* Overlay for mobile - only visible when sidebar is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10 lg:hidden"
          onClick={onClose}
          aria-hidden="true"
        />
      )}

      <aside
        className={`bg-white border-r border-gray-200 w-64 flex-shrink-0 fixed inset-y-0 left-0 z-20 transform transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-auto ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="h-16 border-b border-gray-200 flex items-center justify-between px-6">
          <h2 className="font-semibold text-gray-800">Dashboard</h2>
          <button
            onClick={onClose}
            className="p-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 lg:hidden"
            aria-label="Close sidebar"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <nav className="p-4 space-y-4 overflow-y-auto h-[calc(100%-4rem)]">
          <div className="mb-4">
            <div className="px-3 mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              Main
            </div>
            <Link
              to="/profile"
              onClick={() => handleTabChange("profile")}
              className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg w-full text-left ${
                activeTab === "profile"
                  ? "bg-indigo-50 text-indigo-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <Layers
                className={`mr-3 h-5 w-5 ${
                  activeTab === "profile" ? "text-indigo-700" : "text-gray-500"
                }`}
              />
              Dashboard
            </Link>
            <Link
              to="/analytics"
              onClick={() => handleTabChange("analytics")}
              className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg w-full text-left mt-1 ${
                activeTab === "analytics"
                  ? "bg-indigo-50 text-indigo-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <BarChart3
                className={`mr-3 h-5 w-5 ${
                  activeTab === "analytics"
                    ? "text-indigo-700"
                    : "text-gray-500"
                }`}
              />
              Analytics
            </Link>
            <Link
              to="/settings"
              className="flex items-center px-3 py-2 text-sm font-medium rounded-lg text-gray-700 hover:bg-gray-100 mt-1 w-full text-left"
              onClick={onClose}
            >
              <Settings className="mr-3 h-5 w-5 text-gray-500" />
              Settings
            </Link>
          </div>

          {/* Upgrade to Pro - Moved up from bottom */}
          <div className="mb-4">
            <div className="bg-indigo-50 rounded-lg p-4">
              <div className="flex items-center">
                <Sparkles className="h-5 w-5 text-indigo-600 mr-3" />
                <h3 className="font-medium text-gray-800">Upgrade to Pro</h3>
              </div>
              <p className="mt-1 text-xs text-gray-600">
                Get more features and analytics
              </p>
              <button className="mt-3 w-full py-1.5 px-3 text-xs font-medium bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors">
                Upgrade Now
              </button>
            </div>
          </div>

          <div className="mb-4">
            <div className="px-3 mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              Profile
            </div>
            <Link
              to="/profile"
              onClick={() => handleTabChange("profile")}
              className="flex items-center px-3 py-2 text-sm font-medium rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              <User className="mr-3 h-5 w-5 text-gray-500" />
              Account
            </Link>
            <Link
              to="/links"
              onClick={() => handleTabChange("links")}
              className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg w-full text-left mt-1 ${
                activeTab === "links"
                  ? "bg-indigo-50 text-indigo-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <LinkIcon
                className={`mr-3 h-5 w-5 ${
                  activeTab === "links" ? "text-indigo-700" : "text-gray-500"
                }`}
              />
              My Links
            </Link>
            {/* Sign Out Button */}
            <button
              onClick={handleSignOut}
              className="flex items-center px-3 py-2 text-sm font-medium rounded-lg text-red-600 hover:bg-red-50 w-full text-left mt-1"
            >
              <LogOut className="mr-3 h-5 w-5 text-red-500" />
              Sign Out
            </button>
          </div>

          <div>
            <div className="px-3 mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              Support
            </div>
            <a
              href="#"
              className="flex items-center px-3 py-2 text-sm font-medium rounded-lg text-gray-700 hover:bg-gray-100"
            >
              <MessageCircle className="mr-3 h-5 w-5 text-gray-500" />
              Help Center
            </a>
          </div>
        </nav>
      </aside>
    </>
  );
}
