"use client";

import type React from "react";

import { useState } from "react";
import {
  AlertTriangle,
  ArrowLeft,
  Shield,
  Trash2,
  User,
  X,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { NotificationMessage } from "../dashboard/NotificationMessage";

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleDeleteAccount = async () => {
    if (confirmText !== "DELETE") {
      setError("Please type DELETE to confirm");
      return;
    }

    setIsDeleting(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/account`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to delete account");
      }

      setSuccess("Your account has been deleted successfully");

      // Redirect to login page after a short delay
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (err) {
      setError((err as Error).message);
      setIsDeleting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="mb-6">
          <button
            onClick={() => navigate("/dashboard")}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back to Dashboard
          </button>
        </div>

        <h1 className="text-2xl font-bold text-gray-900 mb-6">Settings</h1>

        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex items-center mb-4">
            <User className="h-5 w-5 text-indigo-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-800">
              Account Settings
            </h2>
          </div>

          <p className="text-gray-600 mb-4">
            Manage your account settings and preferences.
          </p>

          <div className="border-t border-gray-200 pt-4 mt-4">
            <h3 className="text-md font-medium text-gray-800 mb-2">
              Account Information
            </h3>
            <p className="text-gray-600 mb-2">
              More account settings will be available soon.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex items-center mb-4">
            <Shield className="h-5 w-5 text-indigo-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-800">Security</h2>
          </div>

          <p className="text-gray-600 mb-4">
            Manage your security settings and connected accounts.
          </p>

          <div className="border-t border-gray-200 pt-4 mt-4">
            <h3 className="text-md font-medium text-gray-800 mb-2">
              Connected Accounts
            </h3>
            <p className="text-gray-600 mb-2">
              You're currently signed in with Google.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6 border-l-4 border-red-500">
          <div className="flex items-center mb-4">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-800">Danger Zone</h2>
          </div>

          <p className="text-gray-600 mb-6">
            Actions in this section are irreversible. Please proceed with
            caution.
          </p>

          <div className="border-t border-gray-200 pt-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div>
                <h3 className="text-md font-medium text-gray-800 mb-1">
                  Delete Account
                </h3>
                <p className="text-gray-600 text-sm mb-4 md:mb-0">
                  Permanently delete your account and all your data.
                </p>
              </div>
              <button
                onClick={() => setIsDeleteDialogOpen(true)}
                className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors flex items-center justify-center"
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Delete Account
              </button>
            </div>
          </div>
        </div>

        <NotificationMessage success={success} error={error} />
      </div>

      {/* Delete Account Confirmation Dialog */}
      {isDeleteDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
            <button
              onClick={() => {
                setIsDeleteDialogOpen(false);
                setConfirmText("");
                setError(null);
              }}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              disabled={isDeleting}
            >
              <X className="h-5 w-5" />
            </button>

            <div className="flex items-center mb-4">
              <div className="bg-red-100 p-2 rounded-full mr-3">
                <AlertTriangle className="h-6 w-6 text-red-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900">
                Delete Account
              </h3>
            </div>

            <div className="mb-6">
              <p className="text-gray-700 mb-4">
                This action <span className="font-bold">cannot be undone</span>.
                This will permanently delete your account and remove all your
                data from our servers.
              </p>
              <p className="text-gray-700 mb-4">
                Please type <span className="font-bold">DELETE</span> to
                confirm.
              </p>
              <input
                type="text"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                placeholder="Type DELETE to confirm"
                disabled={isDeleting}
              />
            </div>

            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setIsDeleteDialogOpen(false);
                  setConfirmText("");
                  setError(null);
                }}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAccount}
                className={`px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center ${
                  isDeleting ? "opacity-70 cursor-not-allowed" : ""
                }`}
                disabled={isDeleting || confirmText !== "DELETE"}
              >
                {isDeleting ? (
                  <>
                    <span className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full mr-2"></span>
                    Deleting...
                  </>
                ) : (
                  <>
                    <Trash2 className="h-4 w-4 mr-2" />
                    Delete Account
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
