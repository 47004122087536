"use client";
import { motion } from "framer-motion";
import { FaChartBar, FaChartLine, FaUsers, FaClock } from "react-icons/fa";

const AnalyticsSection = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-purple-100 to-pink-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-purple-700">
              Powerful Analytics at Your Fingertips
            </h2>
            <p className="text-xl text-purple-600">
              Track your page performance with detailed analytics. Understand
              your audience better and optimize your content strategy.
            </p>

            <div className="grid grid-cols-2 gap-4">
              <div className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
                <div className="h-10 w-10 rounded-full bg-pink-500/20 flex items-center justify-center text-pink-600">
                  <FaUsers className="h-5 w-5" />
                </div>
                <div>
                  <div className="text-sm font-medium text-purple-700">
                    Visitors
                  </div>
                  <div className="text-2xl font-bold text-purple-800">2.4K</div>
                </div>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
                <div className="h-10 w-10 rounded-full bg-cyan-500/20 flex items-center justify-center text-cyan-600">
                  <FaChartLine className="h-5 w-5" />
                </div>
                <div>
                  <div className="text-sm font-medium text-purple-700">
                    Clicks
                  </div>
                  <div className="text-2xl font-bold text-purple-800">8.7K</div>
                </div>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
                <div className="h-10 w-10 rounded-full bg-orange-500/20 flex items-center justify-center text-orange-600">
                  <FaClock className="h-5 w-5" />
                </div>
                <div>
                  <div className="text-sm font-medium text-purple-700">
                    Avg. Time
                  </div>
                  <div className="text-2xl font-bold text-purple-800">2:34</div>
                </div>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
                <div className="h-10 w-10 rounded-full bg-yellow-500/20 flex items-center justify-center text-yellow-600">
                  <FaChartBar className="h-5 w-5" />
                </div>
                <div>
                  <div className="text-sm font-medium text-purple-700">CTR</div>
                  <div className="text-2xl font-bold text-purple-800">
                    24.8%
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
            className="relative"
          >
            <div className="absolute -inset-4 rounded-xl bg-gradient-to-r from-pink-400/30 to-purple-400/30 blur-xl opacity-70"></div>
            <div className="relative bg-white rounded-xl border shadow-xl overflow-hidden">
              <div className="p-1 bg-gradient-to-r from-purple-500 to-pink-500">
                <div className="flex space-x-1">
                  <div className="h-2 w-2 rounded-full bg-red-500"></div>
                  <div className="h-2 w-2 rounded-full bg-yellow-500"></div>
                  <div className="h-2 w-2 rounded-full bg-green-500"></div>
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="font-medium text-purple-700">
                    Analytics Dashboard
                  </h3>
                  <div className="text-xs text-purple-500">Last 30 days</div>
                </div>

                <div className="space-y-6">
                  <div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-purple-600">Page Views</span>
                      <span className="font-medium text-purple-700">
                        12,543
                      </span>
                    </div>
                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div className="h-full bg-gradient-to-r from-purple-500 to-pink-500 w-[85%]"></div>
                    </div>
                  </div>

                  <div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-purple-600">Link Clicks</span>
                      <span className="font-medium text-purple-700">8,721</span>
                    </div>
                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div className="h-full bg-gradient-to-r from-cyan-500 to-blue-500 w-[65%]"></div>
                    </div>
                  </div>

                  <div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-purple-600">Conversion Rate</span>
                      <span className="font-medium text-purple-700">24.8%</span>
                    </div>
                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div className="h-full bg-gradient-to-r from-orange-500 to-yellow-500 w-[25%]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AnalyticsSection;
