"use client";

import type React from "react";
import { useState, useEffect } from "react";
import type { Link, Profile } from "../types";
import { DashboardHeader } from "../dashboard/DashboardHeader";
import { DashboardSidebar } from "../dashboard/DashboardSidebar";
import { ProfileUrlBar } from "../dashboard/ProfileUrlBar";
import { TabNavigation } from "../dashboard/TabNavigation";
import { NotificationMessage } from "../dashboard/NotificationMessage";
import { ProfileSettings } from "../dashboard/profile/ProfileSettings";
import { LinksManagement } from "../dashboard/links/LinksManagement";
import { AnalyticsOverview } from "../dashboard/analytics/AnalyticsOverview";
import { useLocation, useNavigate } from "react-router-dom";
import { Copy, Check } from "lucide-react";

// URL validation function
const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return url.startsWith("http://") || url.startsWith("https://");
  } catch {
    return false;
  }
};

const Dashboard: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveTabFromPath = () => {
    const path = location.pathname;
    if (path === "/links") return "links";
    if (path === "/analytics") return "analytics";
    return "profile"; // Default to profile for /profile or /dashboard
  };

  const [links, setLinks] = useState<Link[]>([]);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [editId, setEditId] = useState<string | null>(null);
  const [buttonColor, setButtonColor] = useState("#000000");
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [socialIcon, setSocialIcon] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState("#6366f1");
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<"profile" | "links" | "analytics">(
    getActiveTabFromPath()
  );
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [profileViews, setProfileViews] = useState<number>(0);
  const [isPro, setIsPro] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [backgroundType, setBackgroundType] = useState<
    "solid" | "gradient" | "pattern"
  >("solid");
  const [gradientDirection, setGradientDirection] = useState("to right");
  const [gradientColors, setGradientColors] = useState<[string, string]>([
    "#6366f1",
    "#8b5cf6",
  ]);
  const [patternSelection, setPatternSelection] = useState("pattern-dots");

  const handleTabChange = (tab: "profile" | "links" | "analytics") => {
    setActiveTab(tab);
    if (tab === "profile") {
      navigate("/profile");
    } else if (tab === "links") {
      navigate("/links");
    } else if (tab === "analytics" && isPro) {
      navigate("/analytics");
    }
  };

  useEffect(() => {
    setActiveTab(getActiveTabFromPath());
  }, [location.pathname]);

  const socialIcons = [
    { value: "", label: "None" },
    { value: "twitter", label: "Twitter" },
    { value: "instagram", label: "Instagram" },
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "youtube", label: "YouTube" },
    { value: "github", label: "GitHub" },
    { value: "tiktok", label: "TikTok" },
    { value: "twitch", label: "Twitch" },
    { value: "discord", label: "Discord" },
  ];

  useEffect(() => {
    console.log("Initial data fetch");
    fetchProfileAndLinks();
  }, []);

  const fetchProfileAndLinks = async () => {
    try {
      console.log("Fetching profile and links");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profile`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to fetch profile");
      const user = await response.json();
      console.log("Profile data from backend:", user);

      const linksResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/links`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!linksResponse.ok) throw new Error("Failed to fetch links");
      const fetchedLinks = await linksResponse.json();
      console.log("Links data:", fetchedLinks);

      const viewsResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profile/views`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!viewsResponse.ok) throw new Error("Failed to fetch profile views");
      const viewsData = await viewsResponse.json();

      const updatedProfile = {
        name: user.name || "",
        picture: user.picture || "",
        background_color: user.background_color || "#6366f1",
        username: user.username || "",
        bio: user.bio || "",
        links: fetchedLinks || [],
      };
      console.log("Updated profile state:", updatedProfile);

      setProfile(updatedProfile);
      setLinks(fetchedLinks);
      setBackgroundColor(updatedProfile.background_color);
      setUsername(updatedProfile.username); // Sync username
      setBio(updatedProfile.bio); // Sync bio
      setProfileViews(viewsData.views || 0);
      setIsPro(user.pro || false);

      if (user.background_color) {
        parseBackgroundInfo(user.background_color);
      }
    } catch (err) {
      console.error("Error fetching profile and links:", err);
      setError((err as Error).message);
    }
  };

  const parseBackgroundInfo = (bgValue: string) => {
    if (
      bgValue.startsWith("linear-gradient") ||
      bgValue.startsWith("radial-gradient")
    ) {
      setBackgroundType("gradient");

      if (bgValue.startsWith("radial-gradient")) {
        setGradientDirection("circle");
        const colorMatch = bgValue.match(
          /radial-gradient\(circle,\s*(#[0-9a-fA-F]{6}),\s*(#[0-9a-fA-F]{6})\)/
        );
        if (colorMatch && colorMatch.length >= 3) {
          setGradientColors([colorMatch[1], colorMatch[2]]);
        }
      } else {
        const directionMatch = bgValue.match(/linear-gradient\((to [^,]+),/);
        if (directionMatch && directionMatch.length >= 2) {
          setGradientDirection(directionMatch[1]);
        }

        const colorMatch = bgValue.match(
          /linear-gradient\([^,]+,\s*(#[0-9a-fA-F]{6}),\s*(#[0-9a-fA-F]{6})\)/
        );
        if (colorMatch && colorMatch.length >= 3) {
          setGradientColors([colorMatch[1], colorMatch[2]]);
        }
      }
    } else if (bgValue.startsWith("pattern:")) {
      setBackgroundType("pattern");
      setPatternSelection(bgValue.replace("pattern:", ""));
    } else {
      setBackgroundType("solid");
      setBackgroundColor(bgValue);
    }
  };

  const handleAddLink = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!isValidUrl(url)) {
      setError("Please enter a valid URL (e.g., https://example.com)");
      return;
    }

    try {
      let thumbnailBase64: string | undefined;
      if (thumbnailFile) {
        if (thumbnailFile.size > 5 * 1024 * 1024) {
          throw new Error("Thumbnail file size exceeds 5MB");
        }
        const reader = new FileReader();
        thumbnailBase64 = await new Promise((resolve) => {
          reader.onload = () => resolve(reader.result as string);
          reader.readAsDataURL(thumbnailFile);
        });
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/links`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title,
            url,
            button_color: buttonColor,
            thumbnail: thumbnailBase64,
            social_icon: socialIcon || undefined,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to add link");
      }

      const newLink = await response.json();
      setSuccess(`Link "${newLink.title}" added successfully!`);
      setTitle("");
      setUrl("");
      setButtonColor("#000000");
      setThumbnailFile(null);
      setSocialIcon("");
      setLinks((prev) => [...prev, newLink]);
      setProfile((prev) =>
        prev ? { ...prev, links: [...prev.links, newLink] } : prev
      );
      setIsAddingLink(false);
    } catch (err) {
      setError((err as Error).message);
      fetchProfileAndLinks();
    }
  };

  const handleEditLink = async (link: Link) => {
    setEditId(link.id);
    setTitle(link.title);
    setUrl(link.url);
    setButtonColor(link.button_color || "#000000");
    setThumbnailFile(null);
    setSocialIcon(link.social_icon || "");
    setIsAddingLink(true);
  };

  const handleUpdateLink = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editId) return;

    if (!isValidUrl(url)) {
      setError("Please enter a valid URL (e.g., https://example.com)");
      return;
    }

    try {
      let thumbnailBase64: string | undefined;
      if (thumbnailFile) {
        if (thumbnailFile.size > 5 * 1024 * 1024) {
          throw new Error("Thumbnail file size exceeds 5MB");
        }
        const reader = new FileReader();
        thumbnailBase64 = await new Promise((resolve) => {
          reader.onload = () => resolve(reader.result as string);
          reader.readAsDataURL(thumbnailFile);
        });
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/links/${editId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title,
            url,
            button_color: buttonColor,
            thumbnail: thumbnailBase64,
            social_icon: socialIcon || undefined,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to update link");
      }

      const updatedLink = await response.json();
      setSuccess(`Link "${updatedLink.title}" updated successfully!`);
      setEditId(null);
      setTitle("");
      setUrl("");
      setButtonColor("#000000");
      setThumbnailFile(null);
      setSocialIcon("");
      setLinks((prev) =>
        prev.map((link) => (link.id === updatedLink.id ? updatedLink : link))
      );
      setProfile((prev) =>
        prev
          ? {
              ...prev,
              links: prev.links.map((link) =>
                link.id === updatedLink.id ? updatedLink : link
              ),
            }
          : prev
      );
      setIsAddingLink(false);
    } catch (err) {
      setError((err as Error).message);
      fetchProfileAndLinks();
    }
  };

  const handleDeleteLink = async (id: string) => {
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/links/${id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to delete link");
      }

      setSuccess("Link deleted successfully!");
      setLinks((prev) => prev.filter((link) => link.id !== id));
      setProfile((prev) =>
        prev
          ? { ...prev, links: prev.links.filter((link) => link.id !== id) }
          : prev
      );
    } catch (err) {
      setError((err as Error).message);
      fetchProfileAndLinks();
    }
  };

  const handleMoveLink = async (id: string, direction: "up" | "down") => {
    const currentIndex = links.findIndex((link) => link.id === id);
    if (direction === "up" && currentIndex === 0) return;
    if (direction === "down" && currentIndex === links.length - 1) return;

    const newLinks = [...links];
    const targetIndex =
      direction === "up" ? currentIndex - 1 : currentIndex + 1;
    [newLinks[currentIndex], newLinks[targetIndex]] = [
      newLinks[targetIndex],
      newLinks[currentIndex],
    ];

    setLinks(newLinks);
    setProfile((prev) => (prev ? { ...prev, links: newLinks } : prev));
    try {
      const linkIds = newLinks.map((link) => link.id);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/links/order`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ linkIds }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to reorder links");
      }
    } catch (err) {
      setError((err as Error).message);
      fetchProfileAndLinks();
    }
  };

  const handleColorChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setBackgroundColor(newColor);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profile`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ background_color: newColor }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to update color");
      }

      const updatedUser = await response.json();
      setProfile((prev) =>
        prev
          ? { ...prev, background_color: updatedUser.background_color }
          : null
      );
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleBackgroundUpdate = async () => {
    setError(null);
    setSuccess(null);

    try {
      let backgroundValue = backgroundColor;

      if (backgroundType === "gradient") {
        if (gradientDirection === "circle") {
          backgroundValue = `radial-gradient(circle, ${gradientColors[0]}, ${gradientColors[1]})`;
        } else {
          backgroundValue = `linear-gradient(${gradientDirection}, ${gradientColors[0]}, ${gradientColors[1]})`;
        }
      } else if (backgroundType === "pattern") {
        backgroundValue = `pattern:${patternSelection}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profile`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ background_color: backgroundValue }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to update background");
      }

      const updatedUser = await response.json();
      setSuccess("Background updated successfully!");
      setProfile((prev) =>
        prev
          ? { ...prev, background_color: updatedUser.background_color }
          : null
      );
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleUsernameChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profile`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to update username");
      }

      const updatedUser = await response.json();
      setSuccess("Username updated successfully!");
      setProfile((prev) =>
        prev ? { ...prev, username: updatedUser.username } : null
      );
      setUsername(updatedUser.username);
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleBioChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profile`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ bio }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to update bio");
      }

      const updatedUser = await response.json();
      setSuccess("Bio updated successfully!");
      setBio(updatedUser.bio || "");
      setProfile((prev) => (prev ? { ...prev, bio: updatedUser.bio } : null));
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handlePictureUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (!file) {
      setError("No file selected");
      return;
    }

    setError(null);
    setSuccess(null);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base64String = reader.result as string;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/profile/picture`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ image: base64String }),
            credentials: "include",
          }
        );

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || "Failed to upload picture");
        }

        const updatedUser = await response.json();
        setSuccess("Profile picture uploaded successfully!");
        setProfile((prev) =>
          prev ? { ...prev, picture: updatedUser.picture } : prev
        );
      } catch (err) {
        setError((err as Error).message);
        fetchProfileAndLinks();
      }
    };
    reader.onerror = () => {
      setError("Failed to read file");
    };
  };

  const copyProfileUrl = () => {
    if (!profile?.username) return;

    const profileUrl = `${window.location.origin}/u/${profile.username}`;
    navigator.clipboard.writeText(profileUrl);
    setCopied(true);
    setSuccess("Profile URL copied to clipboard!");

    setTimeout(() => {
      setCopied(false);
      setSuccess(null);
    }, 2000);
  };

  function getContrastColor(hexColor: string) {
    const r = Number.parseInt(hexColor.slice(1, 3), 16);
    const g = Number.parseInt(hexColor.slice(3, 5), 16);
    const b = Number.parseInt(hexColor.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "#000000" : "#ffffff";
  }

  useEffect(() => {
    if (profile?.background_color) {
      parseBackgroundInfo(profile.background_color);
    }
  }, [profile?.background_color]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <DashboardHeader
        profileName={profile?.name || ""}
        profilePicture={profile?.picture}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
      />

      <div className="flex flex-1 overflow-hidden">
        <DashboardSidebar
          isOpen={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          activeTab={activeTab}
          setActiveTab={handleTabChange}
        />

        <main className="flex-1 overflow-auto">
          <div className="max-w-7xl mx-auto px-4 py-6">
            <div className="mb-6">
              <ProfileUrlBar username={profile?.username || ""} />
              {profile?.username && (
                <div className="mt-2 flex items-center">
                  <input
                    type="text"
                    value={`${window.location.origin}/u/${profile.username}`}
                    readOnly
                    className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-700"
                  />
                  <button
                    onClick={copyProfileUrl}
                    className="ml-2 p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 flex items-center"
                    title="Copy to clipboard"
                  >
                    {copied ? (
                      <Check className="h-5 w-5" />
                    ) : (
                      <Copy className="h-5 w-5" />
                    )}
                  </button>
                </div>
              )}
            </div>

            <TabNavigation
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              showAnalytics={isPro}
            />

            {activeTab === "profile" ? (
              <ProfileSettings
                profile={profile}
                username={username}
                bio={bio}
                backgroundColor={backgroundColor}
                backgroundType={backgroundType}
                gradientDirection={gradientDirection}
                gradientColors={gradientColors}
                patternSelection={patternSelection}
                links={links}
                handleUsernameChange={handleUsernameChange}
                handleBioChange={handleBioChange}
                handlePictureUpload={handlePictureUpload}
                handleColorChange={handleColorChange}
                handleBackgroundUpdate={handleBackgroundUpdate}
                setUsername={setUsername}
                setBio={setBio}
                setBackgroundColor={setBackgroundColor}
                setBackgroundType={setBackgroundType}
                setGradientDirection={setGradientDirection}
                setGradientColors={setGradientColors}
                setPatternSelection={setPatternSelection}
                fetchProfileAndLinks={fetchProfileAndLinks}
              />
            ) : activeTab === "links" ? (
              <LinksManagement
                profile={profile}
                links={links}
                isAddingLink={isAddingLink}
                editId={editId}
                title={title}
                url={url}
                buttonColor={buttonColor}
                socialIcon={socialIcon}
                thumbnailFile={thumbnailFile}
                backgroundColor={backgroundColor}
                username={username}
                bio={bio}
                setIsAddingLink={setIsAddingLink}
                setEditId={setEditId}
                setTitle={setTitle}
                setUrl={setUrl}
                setButtonColor={setButtonColor}
                setSocialIcon={setSocialIcon}
                setThumbnailFile={setThumbnailFile}
                handleAddLink={handleAddLink}
                handleEditLink={handleEditLink}
                handleUpdateLink={handleUpdateLink}
                handleDeleteLink={handleDeleteLink}
                handleMoveLink={handleMoveLink}
                fetchProfileAndLinks={fetchProfileAndLinks}
                getContrastColor={getContrastColor}
                socialIcons={socialIcons}
              />
            ) : isPro && activeTab === "analytics" ? (
              <AnalyticsOverview
                links={links}
                profileViews={profileViews}
                setActiveTab={handleTabChange}
                getContrastColor={getContrastColor}
              />
            ) : (
              <div className="text-center py-8">
                <h2 className="text-xl font-semibold text-gray-800">
                  Upgrade to Pro
                </h2>
                <p className="text-gray-600 mt-2">
                  Analytics is available only to Pro users. Upgrade to access
                  this feature.
                </p>
                <button
                  className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                  onClick={() => alert("Stripe integration coming soon!")}
                >
                  Upgrade Now
                </button>
              </div>
            )}

            <NotificationMessage success={success} error={error} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
