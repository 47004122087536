import { CheckCircle2 } from "lucide-react"

interface NotificationMessageProps {
  success?: string | null
  error?: string | null
}

export function NotificationMessage({ success, error }: NotificationMessageProps) {
  if (!success && !error) return null

  if (success) {
    return (
      <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded-lg shadow-sm mt-6 transition-opacity duration-300">
        <div className="flex">
          <CheckCircle2 className="h-5 w-5 text-green-500 mr-3 flex-shrink-0" />
          <p className="text-gray-700">{success}</p>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg shadow-sm mt-6 transition-opacity duration-300">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="ml-3 text-gray-700">{error}</p>
        </div>
      </div>
    )
  }

  return null
}

