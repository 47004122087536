"use client";

import type React from "react";
import { useEffect, useState } from "react";

interface ProfileData {
  background_color?: string;
  name?: string;
  picture?: string;
  username?: string;
  bio?: string;
  links?: any[];
  views?: number;
}

interface PublicProfileProps {
  profile?: ProfileData;
  previewData?: ProfileData;
  onLinkClick?: (link: any) => void;
  username?: string;
}

const PublicProfile: React.FC<PublicProfileProps> = ({
  profile,
  previewData,
  onLinkClick,
  username: propUsername,
}) => {
  const [fetchedProfile, setFetchedProfile] = useState<ProfileData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Use the provided data or fetch from API
  const data = previewData || profile || fetchedProfile || {};

  // Fetch profile data if not provided as props
  useEffect(() => {
    const fetchProfile = async () => {
      // If we have previewData or profile props, don't fetch
      if (previewData || profile) return;

      // If no username provided, can't fetch
      const usernameToFetch =
        propUsername || window.location.pathname.split("/u/")[1];
      if (!usernameToFetch) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/u/${usernameToFetch}`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch profile: ${response.statusText}`);
        }

        const profileData = await response.json();
        setFetchedProfile(profileData);
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError("Failed to load profile. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [previewData, profile, propUsername]);

  const getBackgroundStyle = () => {
    const bgValue = data.background_color || "#ffffff";

    if (
      bgValue.startsWith("linear-gradient") ||
      bgValue.startsWith("radial-gradient")
    ) {
      return { background: bgValue };
    } else if (bgValue.startsWith("pattern:")) {
      const patternName = bgValue.replace("pattern:", "");

      // Return appropriate pattern styles based on pattern name
      switch (patternName) {
        case "pattern-dots":
          return {
            backgroundColor: "#f3f4f6",
            backgroundImage: "radial-gradient(black 1px, transparent 1px)",
            backgroundSize: "20px 20px",
          };
        case "pattern-grid":
          return {
            backgroundColor: "#f3f4f6",
            backgroundImage:
              "linear-gradient(to right, #8882 1px, transparent 1px), linear-gradient(to bottom, #8882 1px, transparent 1px)",
            backgroundSize: "20px 20px",
          };
        case "pattern-waves":
          return {
            backgroundColor: "#eff6ff",
            backgroundImage:
              "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAwIDUwYzAgNTAgMTAwIDUwIDEwMCAxMDBzLTEwMCA1MC0xMDAgMTAwIDAtNTAgMC0xMDAgLTEwMC01MCAtMTAwLTEwMCAxMDAtNTAgMTAwLTUweiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjM2NmYxMjAiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==')",
          };
        case "pattern-zigzag":
          return {
            backgroundColor: "#f3f4f6",
            backgroundImage:
              "linear-gradient(135deg, #88888822 25%, transparent 25%), linear-gradient(225deg, #88888822 25%, transparent 25%), linear-gradient(315deg, #88888822 25%, transparent 25%), linear-gradient(45deg, #88888822 25%, transparent 25%)",
            backgroundSize: "20px 20px",
          };
        case "pattern-stripes":
          return {
            backgroundColor: "#f3f4f6",
            backgroundImage:
              "linear-gradient(135deg, #88888822 10%, transparent 0, transparent 50%, #88888822 0, #88888822 60%, transparent 0, transparent)",
            backgroundSize: "15px 15px",
          };
        default:
          return { backgroundColor: bgValue };
      }
    } else {
      return { backgroundColor: bgValue };
    }
  };

  // Handle link clicks
  const handleLinkClick = async (link: any) => {
    if (onLinkClick) {
      onLinkClick(link);
      return;
    }

    // If no onLinkClick provided, track the click via API
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/links/${link.id}/click`,
        {
          method: "POST",
        }
      );

      // Open the link in a new tab
      window.open(link.url, "_blank", "noopener,noreferrer");
    } catch (err) {
      console.error("Error tracking link click:", err);
      // Still open the link even if tracking fails
      window.open(link.url, "_blank", "noopener,noreferrer");
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center py-10 px-4 bg-gray-50">
        <div className="w-16 h-16 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600">Loading profile...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center py-10 px-4 bg-gray-50">
        <div className="w-full max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden p-6">
          <div className="text-center">
            <h1 className="text-xl font-bold text-red-600 mb-2">Error</h1>
            <p className="text-gray-600">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  // If no data and not loading, show not found
  if (!data.name && !data.username && !isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center py-10 px-4 bg-gray-50">
        <div className="w-full max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden p-6">
          <div className="text-center">
            <h1 className="text-xl font-bold text-gray-800 mb-2">
              Profile Not Found
            </h1>
            <p className="text-gray-600">
              The profile you're looking for doesn't exist or has been removed.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex flex-col items-center py-10 px-4"
      style={getBackgroundStyle()}
    >
      {/* Profile Content */}
      <div className="w-full max-w-md mx-auto rounded-xl overflow-hidden p-6 ">
        {data.picture ? (
          <img
            src={data.picture || "/placeholder.svg"}
            alt={data.name || "Profile"}
            className="h-24 w-24 rounded-full mx-auto border-4 border-white shadow-md object-cover"
          />
        ) : (
          <div className="h-24 w-24 rounded-full mx-auto bg-indigo-100 flex items-center justify-center border-4 border-white shadow-md">
            <span className="text-2xl font-bold text-indigo-500">
              {data.name ? data.name.charAt(0).toUpperCase() : "U"}
            </span>
          </div>
        )}

        <div className="text-center mt-4">
          <h1 className="text-xl font-bold text-gray-800">
            {data.name || "User"}
          </h1>
          {data.username && (
            <p className="text-indigo-600 font-medium">@{data.username}</p>
          )}
          {data.bio && <p className="text-gray-600 mt-2">{data.bio}</p>}

          {data.views !== undefined && (
            <p className="text-gray-500 text-sm mt-2">
              {data.views} profile views
            </p>
          )}
        </div>

        <div className="mt-6 space-y-3">
          {data.links && data.links.length > 0 ? (
            data.links.map((link: any) => (
              <button
                key={link.id}
                onClick={() => handleLinkClick(link)}
                className="w-full py-3 px-4 rounded-lg font-medium text-center transition-all hover:opacity-90 hover:shadow-md"
                style={{
                  backgroundColor: link.button_color || "#000000",
                  color: getContrastColor(link.button_color || "#000000"),
                }}
              >
                {link.title}
              </button>
            ))
          ) : (
            <p className="text-center text-gray-500 py-4">No links added yet</p>
          )}
        </div>
      </div>
    </div>
  );
};

// Helper function to determine text color based on background color
function getContrastColor(hexColor: string) {
  // Convert hex to RGB
  const r = Number.parseInt(hexColor.slice(1, 3), 16);
  const g = Number.parseInt(hexColor.slice(3, 5), 16);
  const b = Number.parseInt(hexColor.slice(5, 7), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.5 ? "#000000" : "#ffffff";
}

export default PublicProfile;
