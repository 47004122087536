"use client"

import type React from "react"

import { Edit, ExternalLink, Plus } from "lucide-react"

interface LinkFormProps {
  editId: string | null
  title: string
  url: string
  buttonColor: string
  socialIcon: string
  thumbnailFile: File | null
  setIsAddingLink: (isAdding: boolean) => void
  setEditId: (id: string | null) => void
  setTitle: (title: string) => void
  setUrl: (url: string) => void
  setButtonColor: (color: string) => void
  setSocialIcon: (icon: string) => void
  setThumbnailFile: (file: File | null) => void
  handleAddLink: (e: React.FormEvent) => Promise<void>
  handleUpdateLink: (e: React.FormEvent) => Promise<void>
  getContrastColor: (hexColor: string) => string
  socialIcons: { value: string; label: string }[]
}

export function LinkForm({
  editId,
  title,
  url,
  buttonColor,
  socialIcon,
  thumbnailFile,
  setIsAddingLink,
  setEditId,
  setTitle,
  setUrl,
  setButtonColor,
  setSocialIcon,
  setThumbnailFile,
  handleAddLink,
  handleUpdateLink,
  getContrastColor,
  socialIcons,
}: LinkFormProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm overflow-hidden transition-all duration-300">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center">
          {editId ? (
            <>
              <Edit className="h-5 w-5 mr-2 text-indigo-500" />
              Edit Link
            </>
          ) : (
            <>
              <Plus className="h-5 w-5 mr-2 text-indigo-500" />
              Add New Link
            </>
          )}
        </h2>
        <button
          onClick={() => {
            setIsAddingLink(false)
            setEditId(null)
            setTitle("")
            setUrl("")
            setButtonColor("#000000")
            setThumbnailFile(null)
            setSocialIcon("")
          }}
          className="text-gray-500 hover:text-gray-700"
        >
          Cancel
        </button>
      </div>

      <form onSubmit={editId ? handleUpdateLink : handleAddLink} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-medium mb-1">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-3 bg-white border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
              placeholder="My Website"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">URL</label>
            <div className="relative">
              <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="w-full p-3 pl-10 bg-white border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                placeholder="https://example.com"
                required
              />
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <ExternalLink className="h-4 w-4 text-gray-500" />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-medium mb-1">Button Color</label>
            <div className="flex items-center space-x-2">
              <input
                type="color"
                value={buttonColor}
                onChange={(e) => setButtonColor(e.target.value)}
                className="w-12 h-10 cursor-pointer border border-gray-300 rounded-lg"
              />
              <div
                className="flex-1 h-10 border border-gray-300 rounded-lg flex items-center justify-center font-medium"
                style={{
                  backgroundColor: buttonColor,
                  color: getContrastColor(buttonColor),
                }}
              >
                Preview
              </div>
            </div>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">Social Icon</label>
            <select
              value={socialIcon}
              onChange={(e) => setSocialIcon(e.target.value)}
              className="w-full p-3 bg-white border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
            >
              {socialIcons.map((icon) => (
                <option key={icon.value} value={icon.value}>
                  {icon.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-1">Custom Thumbnail</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setThumbnailFile(e.target.files?.[0] || null)}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100 transition-colors"
          />
        </div>

        <button
          type="submit"
          className="w-full py-2.5 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition-all duration-200 flex items-center justify-center"
        >
          {editId ? "Update Link" : "Add Link"}
        </button>
      </form>
    </div>
  )
}

