"use client"

import type React from "react"

interface ProfileBasicInfoProps {
  username: string
  bio: string
  handleUsernameChange: (e: React.FormEvent) => Promise<void>
  handleBioChange: (e: React.FormEvent) => Promise<void>
  setUsername: (username: string) => void
  setBio: (bio: string) => void
}

export function ProfileBasicInfo({
  username,
  bio,
  handleUsernameChange,
  handleBioChange,
  setUsername,
  setBio,
}: ProfileBasicInfoProps) {
  return (
    <>
      <form onSubmit={handleUsernameChange} className="space-y-4 mb-6">
        <div>
          <label className="block text-gray-700 font-medium mb-1">Username</label>
          <div className="relative">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-3 pl-8 bg-white border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
              placeholder="Set your username"
            />
            <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
              <span className="text-gray-500">@</span>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-2.5 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition-all duration-200 flex items-center justify-center"
        >
          Update Username
        </button>
      </form>

      <form onSubmit={handleBioChange} className="space-y-4 mb-6">
        <div>
          <label className="block text-gray-700 font-medium mb-1">Bio</label>
          <textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            className="w-full p-3 bg-white border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
            placeholder="Tell the world about yourself"
            rows={3}
            maxLength={150}
          />
          <p className="text-sm text-gray-500 mt-1">{bio.length}/150 characters</p>
        </div>
        <button
          type="submit"
          className="w-full py-2.5 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition-all duration-200 flex items-center justify-center"
        >
          Update Bio
        </button>
      </form>
    </>
  )
}

