import type { Link, Profile } from "../../types";
import PublicProfile from "../../../components/Profile/PublicProfile";

interface LinksPreviewProps {
  profile: Profile | null;
  backgroundColor: string;
  links: Link[];
  username: string;
  bio: string;
  fetchProfileAndLinks: () => Promise<void>;
}

export function LinksPreview({
  profile,
  backgroundColor,
  links,
  username,
  bio,
  fetchProfileAndLinks,
}: LinksPreviewProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Live Preview</h2>
      <div className="overflow-hidden rounded-lg border border-gray-200">
        {profile ? (
          <div className="transform scale-[0.85] origin-top">
            <PublicProfile
              previewData={{
                name: profile.name,
                picture: profile.picture,
                background_color: backgroundColor,
                links: links,
                username: username,
                bio: bio,
              }}
              onLinkClick={fetchProfileAndLinks}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-64">
            <p className="text-gray-500">Loading preview...</p>
          </div>
        )}
      </div>
    </div>
  );
}
