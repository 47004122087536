import type React from "react";
import { ImageIcon, Palette, User } from "lucide-react";
import { ProfileBasicInfo } from "./ProfileBasicInfo";
import { ProfilePicture } from "./ProfilePicture";
import { ProfileColorPicker } from "./ProfileColorPicker";
import { ProfilePreview } from "./ProfilePreview";
import { ProfileTips } from "./ProfileTips";
import type { Link, Profile } from "../../types";

interface ProfileSettingsProps {
  profile: Profile | null;
  username: string;
  bio: string;
  backgroundColor: string;
  backgroundType: "solid" | "gradient" | "pattern";
  gradientDirection: string;
  gradientColors: [string, string];
  patternSelection: string;
  links: Link[];
  handleUsernameChange: (e: React.FormEvent) => Promise<void>;
  handleBioChange: (e: React.FormEvent) => Promise<void>;
  handlePictureUpload: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleColorChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleBackgroundUpdate: () => Promise<void>;
  setUsername: (username: string) => void;
  setBio: (bio: string) => void;
  setBackgroundColor: (color: string) => void;
  setBackgroundType: (type: "solid" | "gradient" | "pattern") => void;
  setGradientDirection: (direction: string) => void;
  setGradientColors: (colors: [string, string]) => void;
  setPatternSelection: (pattern: string) => void;
  fetchProfileAndLinks: () => Promise<void>;
}

export function ProfileSettings({
  profile,
  username,
  bio,
  backgroundColor,
  backgroundType,
  gradientDirection,
  gradientColors,
  patternSelection,
  links,
  handleUsernameChange,
  handleBioChange,
  handlePictureUpload,
  handleColorChange,
  handleBackgroundUpdate,
  setUsername,
  setBio,
  setBackgroundColor,
  setBackgroundType,
  setGradientDirection,
  setGradientColors,
  setPatternSelection,
  fetchProfileAndLinks,
}: ProfileSettingsProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2 space-y-6">
        {/* Profile Settings */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <User className="h-5 w-5 mr-2 text-indigo-500" />
            Basic Information
          </h2>

          <ProfileBasicInfo
            username={username}
            bio={bio}
            handleUsernameChange={handleUsernameChange}
            handleBioChange={handleBioChange}
            setUsername={setUsername}
            setBio={setBio}
          />
        </div>

        {/* Profile Picture */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <ImageIcon className="h-5 w-5 mr-2 text-indigo-500" />
            Profile Picture
          </h2>

          <ProfilePicture
            profile={profile}
            handlePictureUpload={handlePictureUpload}
          />
        </div>

        {/* Background Color */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <Palette className="h-5 w-5 mr-2 text-indigo-500" />
            Background Style
          </h2>

          <ProfileColorPicker
            backgroundColor={backgroundColor}
            handleColorChange={handleColorChange}
            setBackgroundColor={setBackgroundColor}
            backgroundType={backgroundType}
            setBackgroundType={setBackgroundType}
            gradientDirection={gradientDirection}
            setGradientDirection={setGradientDirection}
            gradientColors={gradientColors}
            setGradientColors={setGradientColors}
            patternSelection={patternSelection}
            setPatternSelection={setPatternSelection}
            handleBackgroundUpdate={handleBackgroundUpdate}
          />
        </div>
      </div>

      {/* Preview */}
      <div className="space-y-6">
        <ProfilePreview
          profile={profile}
          backgroundColor={backgroundColor}
          backgroundType={backgroundType}
          gradientDirection={gradientDirection}
          gradientColors={gradientColors}
          patternSelection={patternSelection}
          links={links}
          username={username}
          bio={bio}
          fetchProfileAndLinks={fetchProfileAndLinks}
        />
        <ProfileTips />
      </div>
    </div>
  );
}
export default ProfileSettings;
