import { Check } from "lucide-react"

export function ProfileTips() {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Quick Tips</h2>
      <ul className="space-y-3">
        <li className="flex items-start">
          <div className="bg-green-100 p-1 rounded-full mr-3 mt-0.5">
            <Check className="h-4 w-4 text-green-600" />
          </div>
          <p className="text-gray-600">Add your most important links first - they'll appear at the top</p>
        </li>
        <li className="flex items-start">
          <div className="bg-green-100 p-1 rounded-full mr-3 mt-0.5">
            <Check className="h-4 w-4 text-green-600" />
          </div>
          <p className="text-gray-600">Use a short, memorable username for easy sharing</p>
        </li>
        <li className="flex items-start">
          <div className="bg-green-100 p-1 rounded-full mr-3 mt-0.5">
            <Check className="h-4 w-4 text-green-600" />
          </div>
          <p className="text-gray-600">Choose button colors that match your brand or style</p>
        </li>
        <li className="flex items-start">
          <div className="bg-green-100 p-1 rounded-full mr-3 mt-0.5">
            <Check className="h-4 w-4 text-green-600" />
          </div>
          <p className="text-gray-600">Add a bio to tell visitors more about yourself</p>
        </li>
      </ul>
    </div>
  )
}

